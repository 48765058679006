import React from "react";
import Button from "reusecore/src/elements/Button";
import AnchorLink from "react-anchor-link-smooth-scroll";

function BookBtn(props) {
  return (
    <React.Fragment>
      {props.scrollToPricing ? (
        <AnchorLink href="#pricing_section">
          <Button
            title="Get Started"
            fontSize="14px"
            fontWeight="500"
            {...props}
          />
        </AnchorLink>
      ) : (
        <a href="https://app.botter.io" target="_blank">
          <Button
            title="Get Started"
            fontSize="14px"
            fontWeight="500"
            {...props}
          />
        </a>
      )}
    </React.Fragment>
  );
}

export default BookBtn;
