import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Logo from 'reusecore/src/elements/UI/Logo';
import Container from 'common/src/components/UI/Container';
import FooterWrapper from './footer.style';
import Particles from '../Particle';
import SocialProfile from '../SocialProfile';

import LogoImage from 'common/src/assets/image/saas/logo.png';

const Footer = ({ joinText, logoStyle, textStyle, btnStyle }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        SOCIAL_PROFILES {
          icon
          url
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Particles />
      <Container className="footer_container">
        <Box className="footer_inner_container">
          <Box>
            <Logo
              href="#"
              logoSrc={LogoImage}
              title="Langbot Logo"
              logoStyle={logoStyle}
            />
            <a href="mailto: info@botter.io">
              <Text content="info@langbot.io" {...textStyle} />
            </a>
            <Text content="© 2021 Langbot, Inc." {...textStyle} />
          </Box>
          <Box>
            <Text content="Join our communities" {...joinText} />
            <SocialProfile
              className="footer_social"
              items={Data.saasJson.SOCIAL_PROFILES}
              iconSize={18}
            />
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  // Default logo size
  logoStyle: {
    width: '100px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#444444',
    fontSize: '16px',
    fontWeight: '300',
    mb: '10px',
  },
  joinText: {
    color: '#444444',
    fontSize: '16px',
    maxWidth: '250px',
    fontWeight: '500',
    textAlign: 'center',
  },
};

export default Footer;
