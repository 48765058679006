import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'common/src/components/UI/Container';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import StoryWrapper, { LogoWrapper } from './story.style';

function StorySection() {
  const data = useStaticQuery(graphql`
    query {
      saasJson {
        News {
          id
          title
          link
          image {
            publicURL
          }
        }
      }
    }
  `);
  const { News } = data.saasJson;

  return (
    <Box id="story">
      <Box
        as="section"
        mt={['60px', '80px', '80px', '120px']}
        mb={['60px', '80px', '80px', '120px']}
      >
        <Container>
          <Box>
            <Text
              content="STORY"
              textAlign="center"
              fontSize="14px"
              letterSpacing="0.15em"
              fontWeight="700"
              color="#5268db"
            />
            <Heading
              content="LangBot"
              textAlign="center"
              fontSize={['20px', '24px']}
              fontWeight="400"
              color="#0f2137"
              letterSpacing="-0.025em"
            />
          </Box>
          <StoryWrapper>
            <Box className="innerBox">
              <p style={{ fontSize: 18, fontWeight: 300 }}>
                The first bot built on our platform to teach languages has been
                used by over{' '}
                <span style={{ fontWeight: 600, fontSize: 20 }}>
                  300,000 users
                </span>{' '}
                worldwide who had{' '}
                <span style={{ fontWeight: 600, fontSize: 20 }}>
                  20 million
                </span>{' '}
                interactions with the bot. It also won several awards including
                one from Facebook and has been covered by some big media
                outlets.
              </p>
              <LogoWrapper>
                {News.map((item) => (
                  <a
                    href={item.link}
                    key={`partner-key${item.id}`}
                    target="_blank"
                    rel="noopener"
                  >
                    <Image
                      className="logo"
                      src={item.image.publicURL}
                      alt={item.title}
                    />
                  </a>
                ))}
              </LogoWrapper>
              <div style={{ textAlign: 'center' }}>
                <a href="https://m.me/langbotio" target="_blank" rel="noopener">
                  <Button
                    title="Try the bot"
                    fontWeight="500"
                    variant="outlined"
                    color="#5167db"
                  />
                </a>
                <AnchorLink href="#steps">
                  <Button
                    title="Build my own"
                    fontWeight="500"
                    ml={['0', '5', '5', '5']}
                    mt={['10', '0', '0', '0']}
                    variant="outlined"
                    color="#5167db"
                  />
                </AnchorLink>
              </div>
            </Box>
          </StoryWrapper>
        </Container>
      </Box>
    </Box>
  );
}

StorySection.propTypes = {};

export default StorySection;
