import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { saasTheme } from "common/src/theme/saas";
import { ResetCSS } from "common/src/assets/css/style";
import { GlobalStyle, ContentWrapper } from "../containers/Saas/saas.style";
import Navbar from "../containers/Saas/Navbar";
import BannerSection from "../containers/Saas/BannerSection";
import ServiceSection from "../containers/Saas/ServiceSection";
import FaqSection from "../containers/Saas/FaqSection";
import Footer from "../containers/Saas/Footer";
import PricingSection from "../containers/Saas/PricingSection";
import TimelineSection from "../containers/Saas/TimelineSection";
import TestimonialSection from "../containers/Saas/TestimonialSection";
import PartnerSection from "../containers/Saas/PartnerSection";
import QualitySection from "../containers/Saas/QualitySection";
import StorySection from "../containers/Saas/StorySection";
import AppSlider from "../containers/Saas/AppSlider";
import { DrawerProvider } from "common/src/contexts/DrawerContext";
// import Drift from "react-driftjs";
import CustomerChat from './CustomerChat';
import SEO from "../components/seo";

export default () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="Langbot" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <PartnerSection />
          <ServiceSection />
          <QualitySection />
          <AppSlider />
          <StorySection />
          <TimelineSection />
          <TestimonialSection />
          {/* <PricingSection /> */}
          <FaqSection />
          <Footer />
          <CustomerChat />
          {/* <Drift appId="64i86xgiwvsv" /> */}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
