import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import GlideCarousel from "common/src/components/GlideCarousel";
import Box from "reusecore/src/elements/Box";
import GlideSlide from "common/src/components/GlideCarousel/glideSlide";
import { TextWrapper, ClientName } from "./testimonialSection.style";

function TestemonialSlider({
  id,
  title,
  items,
  glideOptions,
  sectionSubTitle,
  btnWrapperStyle,
  commentStyle,
  nameStyle,
  btnStyle,
  designationStyle
}) {
  return (
    <Box className="innerContainer">
      <Text content={title} {...sectionSubTitle} />
      <GlideCarousel
        carouselSelector={id}
        options={glideOptions}
        buttonWrapperStyle={btnWrapperStyle}
        nextButton={
          <Button
            icon={<i className="flaticon-next" />}
            variant="textButton"
            aria-label="next"
            {...btnStyle}
          />
        }
        prevButton={
          <Button
            icon={<i className="flaticon-left-arrow" />}
            variant="textButton"
            aria-label="prev"
            {...btnStyle}
          />
        }
      >
        <Fragment>
          {items.map((item, index) => (
            <GlideSlide key={index}>
              <Fragment>
                <TextWrapper>
                  <i className="flaticon-quotes" />
                  <Text content={item.comment} {...commentStyle} />
                  <ClientName>
                    <Heading content={item.name} {...nameStyle} />
                    <Heading content={item.designation} {...designationStyle} />
                  </ClientName>
                </TextWrapper>
              </Fragment>
            </GlideSlide>
          ))}
        </Fragment>
      </GlideCarousel>
    </Box>
  );
}

TestemonialSlider.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  glideOptions: PropTypes.object.isRequired,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  btnStyle: PropTypes.object,
  btnWrapperStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  commentStyle: PropTypes.object,
  designationStyle: PropTypes.object
};

// TestimonialSection default style
TestemonialSlider.defaultProps = {
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#5268db",
    mb: "20px",
    pl: "12px",
    maxWidth: "50%"
  },
  // client comment style
  commentStyle: {
    color: "#0f2137",
    fontWeight: "400",
    fontSize: ["22px", "22px", "22px", "26px"],
    lineHeight: "1.72",
    mb: "47px"
  },
  // client name style
  nameStyle: {
    as: "h3",
    color: "#343d48",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "30px",
    mb: 0
  },
  // client designation style
  designationStyle: {
    as: "h5",
    color: "rgba(52, 61, 72, 0.8)",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "30px",
    mb: 0,
    ml: ["0", "10px"]
  },
  // glide slider nav controls style
  btnWrapperStyle: {
    position: "absolute",
    bottom: "62px",
    left: "12px"
  },
  // next / prev btn style
  btnStyle: {
    minWidth: "auto",
    minHeight: "auto",
    mr: "13px",
    fontSize: "16px",
    color: "#343d484d"
  }
};

export default TestemonialSlider;
