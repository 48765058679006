import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import NavbarWrapper from "reusecore/src/elements/Navbar";
import Drawer from "reusecore/src/elements/Drawer";
import Logo from "reusecore/src/elements/UI/Logo";
import Box from "reusecore/src/elements/Box";
import Button from "reusecore/src/elements/Button";
import HamburgMenu from "common/src/components/HamburgMenu";
import Container from "common/src/components/UI/Container";
import { DrawerContext } from "common/src/contexts/DrawerContext";
import ScrollSpyMenu from "common/src/components/ScrollSpyMenu";
import LogoImage from "common/src/assets/image/saas/logo.png";
import StartButton from "../StartButton";
import BookButton from "../BookButton";

const Navbar = ({ navbarStyle, button, logoStyle, row, menuWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE"
    });
  };

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Box {...row}>
          <Logo
            href="#"
            logoSrc={LogoImage}
            title="Langbot"
            logoStyle={logoStyle}
          />
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.saasJson.MENU_ITEMS}
              offset={-70}
            />
            <div className="navbar_button">
              {/* <StartButton scrollToPricing {...button} /> */}
              <BookButton />
            </div>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#eb4d4b" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.saasJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
              {/* <div className="navbar_drawer_button">
                <StartButton scrollToPricing {...button} />
              </div> */}
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object
};

Navbar.defaultProps = {
  navbarStyle: {
    className: "hosting_navbar",
    minHeight: "70px",
    display: "block"
  },
  row: {
    flexBox: true,
    alignItems: "center",
    justifyContent: [
      "space-between",
      "space-between",
      "space-between",
      "space-between"
    ],
    width: "100%"
  },
  logoStyle: {
    maxWidth: "130px"
  },
  button: {
    type: "button",
    fontSize: "13px",
    fontWeight: "600",
    color: "white",
    borderRadius: "4px",
    pl: "15px",
    pr: "15px",
    colors: "primaryWithBg",
    minHeight: "auto",
    height: `${1}`
  },
  menuWrapper: {
    flexBox: true,
    alignItems: "center"
  }
};

export default Navbar;
