import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Box from "reusecore/src/elements/Box";
import TestemonialSlider from "./TestemonialSlider";
import TestimonialSectionWrapper from "./testimonialSection.style";

const TestimonialSection = ({}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Client_Testimonials {
          id
          name
          comment
          designation
        }
        Student_Testimonials {
          id
          name
          comment
          designation
        }
      }
    }
  `);

  // Glide js options
  const glideOptions = {
    type: "carousel",
    perView: 1,
    hoverpause: false
  };

  return (
    <TestimonialSectionWrapper id="testimonial_section">
      <Box className="outerContainer">
        <TestemonialSlider
          id="students"
          title="What learners are saying about us"
          items={Data.saasJson.Student_Testimonials}
          glideOptions={{ ...glideOptions, autoplay: 5000 }}
        />
      </Box>
    </TestimonialSectionWrapper>
  );
};

export default TestimonialSection;
