import React from 'react';
import Button from 'reusecore/src/elements/Button';

function BookBtn(props) {
  return (
    <Button
      title="Book a demo"
      variant="outlined"
      minWidth="156px"
      fontSize="14px"
      fontWeight="500"
      color="#5167db"
      onClick={() =>
        window.Calendly.initPopupWidget({
          url: 'https://calendly.com/natig/20min',
        })
      }
      {...props}
    />
  );
}

export default BookBtn;
