import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import FeatureBlock from 'common/src/components/FeatureBlock';
import StartButton from '../StartButton';
import BookButton from '../BookButton';
import BannerWrapper from './bannerSection.style';

import PlatformObject from 'common/src/assets/image/saas/banner/platform.png';

const BannerSection = ({ title, btnStyle, description, outlineBtnStyle }) => {
  const ButtonGroup = () => (
    <Fragment>
      {/* <StartButton scrollToPricing {...btnStyle} /> */}
      <BookButton {...outlineBtnStyle} />
    </Fragment>
  );
  return (
    <BannerWrapper id="banner_section">
      <Container>
        {/* <Particles /> */}
        <Box className="banner_row">
          <Box className="headline_column">
            <FeatureBlock
              title={
                <Heading
                  content="Create and sell conversational language courses"
                  {...title}
                />
              }
              description={
                <Text
                  content="Use langbot to create conversational language courses, assessments, or a study buddy for your students, and let our AI-powered tutor deliver it to learners chatting your Facebook page, Telegram, Instagram, or website 😉"
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
          <Box className="image_wrapper">
            <Image src={PlatformObject} alt="PlatformObject" />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  title: {
    fontSize: ['22px', '34px', '30px', '35px', '40px'],
    fontWeight: '800',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
    margin: '5px',
  },
  outlineBtnStyle: {
    // ml: ["15px", "18px", "18px", "18px"]
  },
};

export default BannerSection;
