import styled from "styled-components";

const StoryWrapper = styled.section`
  display: flex;
  justify-content: center;
  .innerBox {
    width: 50%;
    margin: 20px;

    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
  }
`;

const LogoWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  flex-wrap: wrap;
  .logo {
    max-width: 100px;
    margin: 12px;
  }
`;

export { LogoWrapper };

export default StoryWrapper;
