import styled from "styled-components";
import { themeGet } from "styled-system";
import BannerBG from "common/src/assets/image/saas/saas-banner.jpg";

const BannerWrapper = styled.section`
  padding-top: 180px;
  padding-bottom: 80px;
  background-image: url(${BannerBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  align-items: center;
  display: flex;
  min-height: 100vh;
  @media (max-aspect-ratio: 3/2) {
    min-height: auto;
  }
  @media (max-width: 990px) {
    padding-top: 180px;
    padding-bottom: 60px;
    min-height: auto;
  }
  @media (max-width: 767px) {
    padding-top: 130px;
    padding-bottom: 20px;
    min-height: auto;
  }

  @media only screen and (max-width: 480px) {
    background: none;
  }

  .banner_row {
    display: flex;
    max-width: 1170px;
    width: 100%;
    margin: auto;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  .headline_column {
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .image_wrapper {
    max-width: 50%;
    margin: 0 15px;
    @media (max-width: 767px) {
      margin: 0 85px;
      max-width: 100%;
    }
    @media (max-width: 500px) {
      margin: 0 15px;
      max-width: 100%;
    }
  }

  .particle {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 767px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .button__wrapper {
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    .reusecore__button {
      &.outlined {
        border-color: rgba(82, 104, 219, 0.2);
      }
    }
  }
`;

export default BannerWrapper;
