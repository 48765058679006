import styled from "styled-components";
import FooterImage from "common/src/assets/image/saas/footer-bg.png";

const FooterWrapper = styled.footer`
  position: relative;
  overflow: hidden;
  border-top: 1px solid #efefef;

  .particle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 767px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .footer_container {
    background-repeat: no-repeat;
    background-position: center 50px;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    @media (min-width: 576px) {
      background-image: url(${FooterImage});
    }
    @media (max-width: 990px) {
      padding-bottom: 20px;
    }
  }

  .footer_inner_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 750px) {
      flex-direction: column-reverse;
    }
  }

  .footer_social {
    margin: 0 auto;
    @media (max-width: 600px) {
      margin-left: 0;
      margin-top: 15px;
    }
    a {
      color: #444;
      &:hover {
        color: #000;
        opacity: 0.85;
      }
    }
  }

  .column_two {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }
`;

export default FooterWrapper;
