import styled from "styled-components";

export const SocialProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SocialProfileItem = styled.div`
  margin-right: 18px;
  font-size: 22px;
  a {
    color: #fff;
    transition: 0.15s ease-in-out;
    &:hover {
      color: #3444f1;
    }
  }
`;
